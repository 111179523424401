//import Client from 'shopify-buy';
import Client from 'shopify-buy/index.unoptimized.umd';

// Initializing a client
const client = Client.buildClient({
  domain: 'gethacking.myshopify.com',
  storefrontAccessToken: 'cc070f1b107350e5441c2ef54a17b9cb'
});

var g_shopBoxProductItems;
function setUpGetHackingGraphQLQuery(productsList) {
  return client.graphQLClient.query((root) => {
    var productVariantIds = [];
    productsList.forEach(function(item, index, array) {
      productVariantIds.push(btoa('gid://shopify/ProductVariant/' + item['productVariant']));
    });

    root.add('nodes', {args: {ids: productVariantIds}}, (nodes) => {
      nodes.addInlineFragmentOn('ProductVariant', (variant) => {
        variant.addField('id');
        variant.addField('priceV2', (priceV2) => {
          priceV2.addField('amount');
        });

        variant.add('product', (product) => {
          product.add('title');
        });
      });
    });
  });
}

function createSearchQuery (searchString) {
  return client.graphQLClient.query((root) => {
    root.addConnection('products', {args: {query:'title: '+searchString, first: 10}}, (product) => {
      product.add('title');
      product.addConnection('variants', {args: {first: 10}}, (variant) => {
          variant.addField('id');
      });
    });
  });
}

// for shop-box
function insertItemIntoDOM(lineItemsContainer, item) {
  if (lineItemsContainer) {
    var getHackingItemDiv = document.createElement('div');
    getHackingItemDiv.setAttribute('class', 'gethacking-item');
    var getHackingCheckbox = document.createElement('input');
    getHackingCheckbox.setAttribute('class', 'tw-mr-2 tw-mt-1 tw-leading-tight');
    getHackingCheckbox.setAttribute('type', 'checkbox');
    getHackingCheckbox.setAttribute('checked', true);
    var getHackingSpan = document.createElement('span');
    getHackingSpan.textContent = " " + item['quantity'] + " x " + item['title'];
    getHackingItemDiv.appendChild(getHackingCheckbox);
    getHackingItemDiv.appendChild(getHackingSpan);

    lineItemsContainer.appendChild(getHackingItemDiv);
    item['checked'] = true;

    getHackingCheckbox.onclick = function() {
      item['checked'] = getHackingCheckbox.checked;
      updateGetHackingTotal();
    };
  }
}

function updateGetHackingTotal() {
  const buyButtonText = document.getElementById('buy-total');

  if (buyButtonText) {
    var total = 0;
    g_shopBoxProductItems.forEach(function(item, index, array) {
      if (item['checked']) {
        total += item['price'] * item['quantity'];
      }
    });

    var cents = total % 100;
    var centsString = '.' + cents.toString();
    if (cents == 0) {
      centsString = '';
    } else if (cents < 10) {
      centsString = '.0'  + cents.toString();
    }
    buyButtonText.textContent = 'Buy : S$' +  Math.floor(total/100).toString() + centsString;
  }
}




document.addEventListener("turbolinks:load", ()=> {

  // const products = [{'productVariant': '29490109415489', 'quantity': 1}, {'productVariant': '979911688', 'quantity': 2}]

  // global variable
  const lineItemsContainer = document.getElementById('gethacking-items');


  // if the products variable is defined, we are in activities/show
  // and we should show the shop-box
  if (typeof products !== 'undefined') {
    // Set up GraphQL query
    var fetchProductVariants = setUpGetHackingGraphQLQuery(products);

    client.graphQLClient.send(fetchProductVariants).then((data) => {
      // Clear out the shop-box first
      if (lineItemsContainer) {
        while (lineItemsContainer.firstChild) {
          lineItemsContainer.removeChild(lineItemsContainer.firstChild);
        }
      }

      // bind it to a global variable so it exists outside of this
      g_shopBoxProductItems = data['data']['nodes'];
      g_shopBoxProductItems.forEach(function(item, index, array) {
        item['title'] = item['product']['title'];
        item['price'] = parseInt(parseFloat(item['priceV2']['amount']) * 100, 10);

        products.forEach(function(productInfo, index, array) {
          if (item['id'] == btoa("gid://shopify/ProductVariant/" + productInfo['productVariant'])) {
            item['quantity'] = productInfo['quantity'];
          }
        });

        insertItemIntoDOM(lineItemsContainer, item);
      })
      updateGetHackingTotal();
    });
  }
  // in activity_buyable_products_index
  if (typeof adminProducts !== 'undefined') {
    // Set up GraphQL query
    var fetchProductVariants = setUpGetHackingGraphQLQuery(adminProducts);

    client.graphQLClient.send(fetchProductVariants).then((data) => {
      // bind it to a global variable so it exists outside of this
      var productData = data['data']['nodes'];
      productData.forEach(function(item, index, array) {
        var title = item['product']['title'];
        var id = atob(item['id']).match('gid://shopify/ProductVariant/([0-9]+)')[1];
        var variantIdTd = document.getElementById('part-' + id.toString());
        if (variantIdTd) {
          variantIdTd.textContent = title + ' ('+ id.toString() + ')';
        }

      })
    });
  }

  var partsSearchBtn = document.getElementById('parts_search_btn');
  var partsSearchTextField = document.getElementById('parts_search_textfield');
  if (partsSearchBtn && partsSearchTextField) {
    var partsSearchResultsContainer = document.getElementById('parts_result_container');
    var buyablePartTextField = document.getElementById('buyable_part_variant_id');
    partsSearchBtn.onclick = function() {
      var searchString = partsSearchTextField.value;

      client.graphQLClient.send(createSearchQuery(searchString)).then(({data}) => {
        var searchResults = data['products']['edges'];
        if (searchResults.length > 0) {
            while (partsSearchResultsContainer.firstChild) {
              partsSearchResultsContainer.removeChild(partsSearchResultsContainer.firstChild);
            }
            searchResults.forEach(function(item, index, array) {
              item = item['node'];
              var newProductDiv = document.createElement('div');
              var productId = atob(item['variants']['edges'][0]['node']['id']).match('gid://shopify/ProductVariant/([0-9]+)')[1];
              newProductDiv.setAttribute('data-variantid', productId);
              newProductDiv.classList.add('tw-cursor-pointer');
              newProductDiv.classList.add('tw-border-1');
              newProductDiv.classList.add('tw-m-1');
              newProductDiv.classList.add('tw-p-1');
              newProductDiv.textContent =  item['title'];
              partsSearchResultsContainer.appendChild(newProductDiv);

              newProductDiv.onclick = function() {
                buyablePartTextField.value = productId;
              }
            });
        } else {
          partsSearchResultContainer.textContents = "No results found. If product is sold out, it will not appear";
        }
      });

    };
  }

  // buy button needs to be found on every page 
  var buyBtn = document.getElementById('buy-button');
  if (buyBtn) {
    // Checkout when user clicks on the Buy Button
    buyBtn.onclick = function () {
      var lineItemsToAdd = [ ];

      g_shopBoxProductItems.forEach(function(item, index, array) {
        if (item['checked']) {
          var lineItem = {'variantId': item['id'], quantity: item['quantity'] }
          lineItemsToAdd.push(lineItem);
        }
      });

      if (lineItemsToAdd.length > 0) {
        var query_params = lineItemsToAdd.map(function(item) {
                            var id = atob(item['variantId']).match('gid://shopify/ProductVariant/([0-9]+)')[1];
                            return "updates[" + id + ']=' + item['quantity'];
                          }).join('&');

       location.assign("https://gethacking.com/cart/update?" + query_params);
      /*
       * We don't do a checkout process using shopify's js sdk.
       * as we want to just take them to the cart page
       */ 
       /*
        client.checkout.create().then((checkout) => {
        // Do something with the checkout
          const checkoutId = checkout.id;

            // Add an item to the checkout
            client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
              // Do something with the updated checkout
              // TODO --> redirect page to checkout.webUrl
              console.log("Go to this checkout page: ");
              console.log(checkout.webUrl);
            });
        });
      */
      }
    }
  }
});


document.addEventListener("turbolinks:before-render", () => {
  if (typeof products !== 'undefined') {
    products = undefined;
  }
  
  if (typeof adminProducts !== 'undefined') {
    adminProducts = undefined;
  }

});
/**
const fetchProducts = client.graphQLClient.query((root) => {
  root.add('nodes', {args: {ids: [productId, productId2]}}, (nodes) => {
    nodes.addInlineFragmentOn('Product', (product) => {
      product.add('title');
      product.addConnection('variants', {args: {first:10}}, (variant) => {
        variant.addField('id');
        variant.addField('priceV2', (priceV2) => {
          priceV2.addField('amount');
        });
      });
    });
  });
});
**/






/**
client.product.fetchAll().then((products) => {
  // Do something with the products
  console.log(products);
});


client.product.fetch(productId).then((product) => {
  console.log(product);
});

**/


/**
client.product.fetchQuery(query = 'title:chibitronics', reverse = true).then((product) => {
  // Do something with the product
  console.log(product);
  console.log(product['variants'][0]['price']);
});


**/

/**

const productsQuery = client.graphQLClient.query((root) => {
  root.addConnection('products', {args: {first: 10}}, (product) => {
    product.add('title');
    product.addConnection('variants', {args: {first:10}}, (variant) => {
      variant.addField('priceV2', (priceV2) => {
        priceV2.addField('amount');
      });
    });
  });
});

// Call the send method with the custom products query
client.graphQLClient.send(productsQuery).then(({model, data}) => {
  // Do something with the products
  console.log(model);
  console.log(data);
});

**/
